import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./screens/LandingPage";
import { Provider } from "react-redux";
import { store } from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CheckoutPage from "./screens/CheckoutPage";
import ComingSoon from "./screens/ComingSoon";
import { SnackbarProvider } from "notistack";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SnackbarProvider maxSnack={3}>
        <ComingSoon />
      </SnackbarProvider>
    ),
  },
  {
    path: "/checkout",
    element: (
      <SnackbarProvider maxSnack={3}>
        <CheckoutPage />
      </SnackbarProvider>
    ),
  },
]);

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      key={process.env.REACT_APP_GOOGLE_CLIENT_SECRET}
    >
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
