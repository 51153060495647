import React from "react";

export default function ComingSoon() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        src={require("../assets/Coming-soon-banner.png")}
        style={{ width: "70%", height: "100%" }}
      />
    </div>
  );
}
