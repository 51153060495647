import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  view: null,
};

export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
  },
});

export const { setView } = viewSlice.actions;

export const getView = (state, action) => state.view.view;

export default viewSlice.reducer;
