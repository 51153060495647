import React, { useEffect, useState } from "react";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import { Badge, IconButton, List, Popover, Typography } from "@mui/material";
import { useDispatch, useSelector, connect } from "react-redux";
import { getCartItems } from "../slices/cartSlice";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { getUser, setUser } from "../slices/authSlice";
import { useNavigate } from "react-router-dom";

export default function TopBar() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  const cartItems = useSelector(getCartItems);
  const userItems = useSelector(getUser);

  console.log({ cartItems });

  const handleCartClick = () => {
    navigation("/checkout");
  };

  const successResponseMessage = async (response) => {
    const data = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth`, {
      payload: response,
    });
    dispatch(setUser(data.data));
  };

  useEffect(() => {
    if (userItems?.email) {
      setLoggedIn(true);
      setAccountAnchorEl(null);
    } else {
      setLoggedIn(false);
    }
  }, [userItems]);

  const handleLogout = () => {
    dispatch(setUser(null));
  };

  const errorMessage = (err) => {
    console.log(err);
  };

  const handleAccountClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "100%",
          paddingTop: "1.5rem",
          paddingBottom: "1.5rem",
          paddingLeft: "10vw",
        }}
      >
        <img
          src={require("../assets/logo-no-background.png")}
          style={{
            width: "10%",
            height: "10%",
          }}
        />
      </div>

      {/* Navigation */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: "10vw",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1.5rem",
            fontFamily: "monospace",
            fontSize: "30px",
          }}
          onClick={(event) => navigation("/")}
        >
          HOME
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1.5rem",
            fontFamily: "monospace",
            fontSize: "30px",
          }}
        >
          BLOG
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1.5rem",
          }}
        >
          <IconButton onClick={handleCartClick}>
            <Badge badgeContent={cartItems.length}>
              <ShoppingCartTwoToneIcon />
            </Badge>
          </IconButton>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1.5rem",
          }}
        >
          <IconButton onClick={handleAccountClick}>
            {loggedIn ? (
              <img
                src={userItems.picture}
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                referrerPolicy="no-referrer"
              />
            ) : (
              <AccountCircleTwoToneIcon />
            )}
          </IconButton>
          <Popover
            open={Boolean(accountAnchorEl)}
            anchorEl={accountAnchorEl}
            onClose={handleAccountClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <List>
              <div style={{ width: "150px" }}>
                {loggedIn ? (
                  <button
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "none",
                      border: "none",
                      textAlign: "left",
                      cursor: "pointer",
                      padding: "0.5rem",
                      transition: "background-color 0.3s ease",
                      fontFamily: "monospace",
                      fontSize: "20px",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#f0f0f0")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "transparent")
                    }
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                ) : (
                  <Typography variant="h6" id="account-modal">
                    <GoogleLogin
                      onSuccess={successResponseMessage}
                      onError={errorMessage}
                      ux_mode="popup"
                      text="Login"
                    />
                  </Typography>
                )}
              </div>
            </List>
          </Popover>
        </div>
      </div>
    </div>
  );
}
