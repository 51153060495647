import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const existingItem = state.items.find(
        (item) => item._id === action.payload._id
      );
      if (existingItem) {
        if (existingItem.count && existingItem.size === action.payload.size) {
          return {
            ...state,
            items: state.items.map((item) =>
              item._id === action.payload._id
                ? { ...item, count: item.count + 1 }
                : item
            ),
          };
        } else {
          return {
            ...state,
            items: [...state.items, { ...action.payload, count: 1 }],
          };
        }
      } else {
        return {
          ...state,
          items: [
            ...state.items,
            {
              ...action.payload,
              count: 1,
            },
          ],
        };
      }
    },
    removeItem: (state, action) => {
      state.items = state.items.filter((value) => value.id !== action.payload);
    },
  },
});

export const { addItem, removeItem } = cartSlice.actions;

export const getCartItems = (state, action) => state.cart.items;

export const getCartItemsCount = (state, action) => {
  let count = 0;
  state.cart.items.forEach((item) => (count += item.count));
  return count;
};

export default cartSlice.reducer;
