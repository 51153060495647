import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems, removeItem } from "../slices/cartSlice";
// import * as Razorpay from "razorpay";
export default function CheckoutPage() {
  console.log("entering checkout page");
  const dispatch = useDispatch();
  const cartItem = useSelector(getCartItems);

  const [paymentMethod, setPaymentMethod] = useState("card");
  const [cart, setCart] = useState(cartItem);

  // const initializeRazorpay = async (orderData) => {
  //   const options = {
  //     key: process.env.REACT_APP_RAZORPAY_API_SECRET,
  //     amount: 10000, // amount in the smallest currency unit
  //     currency: "INR", // currency code
  //     name: "Your Company Name",
  //     description: "Payment for your order",
  //     order_id: "random id", // order ID received from your server
  //     handler: function (response) {
  //       // handle success
  //       console.log(response);
  //     },
  //     prefill: {
  //       name: "John Doe",
  //       email: "john@example.com",
  //       contact: "+919876543210",
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#F37254",
  //     },
  //   };

  //   const razorpayInstance = new Razorpay(options);
  //   razorpayInstance.open();
  // };

  useEffect(() => {
    setCart(cartItem);
    console.log({ cartItem });
  }, [cartItem]);

  const handleDeleteItemClick = (id) => {
    // Remove the item from the Redux store
    dispatch(removeItem(id));

    // Remove the item from the local state
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleExpiryMonthChange = (event) => {
    let value = event.target.value;

    if (value.length === 2 && value.indexOf("/") === -1) {
      value += "/";
    }

    if (
      value.length === 2 &&
      event.nativeEvent.inputType === "deleteContentBackward"
    ) {
      value = value.substring(0, 1);
    }

    event.target.value = value;
  };
  return (
    <div>
      <TopBar />
      <div
        style={{
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ paddingLeft: "10vw" }}>
            <div
              style={{
                fontFamily: "monospace",
              }}
            >
              <div
                style={{
                  fontFamily: "monospace",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Billing Details
              </div>

              <div style={{ width: "80%", marginBottom: "1rem" }}>
                <TextField
                  variant="outlined"
                  label="Address Line 1"
                  fullWidth
                />
              </div>
              <div style={{ width: "80%", marginBottom: "1rem" }}>
                <TextField
                  variant="outlined"
                  label="Address Line 2"
                  fullWidth
                />
              </div>
              <div style={{ width: "80%", marginBottom: "1rem" }}>
                <TextField variant="outlined" label="Email" fullWidth />
              </div>
              <div style={{ width: "80%", marginBottom: "1rem" }}>
                <TextField
                  variant="outlined"
                  label="Phone"
                  fullWidth
                  inputMode="numeric"
                />
              </div>
              <div
                style={{
                  fontFamily: "monospace",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Payment Details
              </div>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                  >
                    <FormControlLabel
                      value="card"
                      control={<Radio />}
                      label="Credit/Debit card"
                    />
                    <FormControlLabel
                      value="UPI"
                      control={<Radio />}
                      label="UPI"
                    />
                  </RadioGroup>
                </FormControl>
                {paymentMethod === "card" && (
                  <div>
                    <div style={{ width: "80%", marginBottom: "1rem" }}>
                      <TextField
                        variant="outlined"
                        label="Card Number"
                        fullWidth
                      />
                    </div>
                    <div
                      style={{
                        width: "80%",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "1rem",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="CVV"
                        type="password"
                        inputProps={{
                          maxLength: 3,
                          onInput: (event) => {
                            event.target.value = event.target.value.replace(
                              /\D/g,
                              ""
                            );
                          },
                        }}
                        style={{ marginRight: "1rem" }}
                      />
                      <TextField
                        variant="outlined"
                        label="Expiry Month"
                        inputProps={{ maxLength: 5 }}
                        onChange={handleExpiryMonthChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              MM/YY
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ width: "80%", marginBottom: "1rem" }}>
                      <TextField
                        variant="outlined"
                        label="Card Holder Name"
                        fullWidth
                      />
                    </div>
                  </div>
                )}
                {paymentMethod === "UPI" && (
                  <div style={{ marginBottom: "1rem", width: "80%" }}>
                    <TextField variant="outlined" label="UPI ID" fullWidth />
                  </div>
                )}
              </div>
              <Button
                variant="contained"
                style={{ backgroundColor: "black", color: "white" }}
              >
                Make Payment
              </Button>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <div
                style={{
                  fontFamily: "monospace",
                  fontWeight: "bold",
                  fontSize: "18px",
                  backgroundColor: "rgba(128,128,128,0.4",
                  padding: "1.5rem",
                  borderRadius: "4px",
                }}
              >
                Cart summary
              </div>
              <div
                style={{
                  backgroundColor: "rgba(128,128,128,0.1)",
                  width: "100%",
                }}
              >
                {cart.map((item, index) => {
                  console.log({ item });
                  return (
                    <div
                      style={{
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        fontFamily: "monospace",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <img
                          src={item.fileUrl}
                          style={{ width: "10%", height: "10%" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                            marginLeft: "1rem",
                          }}
                        >
                          <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                            {item.name}
                          </div>
                          <div
                            style={{
                              marginTop: "0.5rem",
                              fontSize: "10px",
                              fontWeight: "normal",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            Qty: {item.count} &nbsp; Size: {item.size}
                            <button
                              style={{
                                marginLeft: "0.5rem",
                                border: "none",
                                padding: "2px",
                                alignItems: "center",
                              }}
                              onClick={() => handleDeleteItemClick(item.id)}
                            >
                              <Delete fontSize="inherit" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>{item.price}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "2rem",
          justifyContent: "space-around",
          backgroundColor: "#f2f2f2",
        }}
      >
        <div
          style={{
            margin: "1.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HeadsetMicOutlinedIcon
            style={{
              fontSize: "25px",
            }}
          />
          <div
            style={{
              fontFamily: "monospace",
              fontWeight: "bolder",
              fontSize: "20px",
              marginTop: "1.5rem",
            }}
          >
            CUSTOMER SERVICE
          </div>
          <div
            style={{
              fontFamily: "monospace",
              fontSize: "14px",
              width: "50%",
              marginTop: "0.5rem",
              opacity: 0.6,
              marginLeft: "2.5rem",
            }}
          >
            Available 8:30am to 4:30pm | Live chat
          </div>
        </div>
        <div
          style={{
            margin: "1.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SentimentSatisfiedOutlinedIcon
            style={{
              fontSize: "25px",
            }}
          />
          <div
            style={{
              fontFamily: "monospace",
              fontWeight: "bold",
              fontSize: "20px",
              marginTop: "1.5rem",
            }}
          >
            EASY RETURNS
          </div>
          <div
            style={{
              fontFamily: "monospace",
              fontSize: "14px",
              width: "50%",
              marginTop: "0.5rem",
              opacity: 0.6,
              marginLeft: "2.5rem",
            }}
          >
            Buy with confidence and enjoy easy returns!
          </div>
        </div>
        <div
          style={{
            margin: "1.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HttpsOutlinedIcon
            style={{
              fontSize: "25px",
            }}
          />
          <div
            style={{
              fontFamily: "monospace",
              fontWeight: "bold",
              fontSize: "20px",
              marginTop: "1.5rem",
            }}
          >
            SECURE PAYMENT
          </div>
          <div
            style={{
              fontFamily: "monospace",
              fontSize: "14px",
              width: "100%",
              marginTop: "0.5rem",
              opacity: 0.6,
              marginLeft: "2.5rem",
            }}
          >
            Shop safely and securely.
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "black",
          color: "white",
          height: "30vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={require("../assets/logo-white.png")}
            style={{
              height: "22vh",
              width: "22vh",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexGrow: 1,
            maxWidth: "50%",
            paddingRight: "3.5rem",
          }}
        >
          <div className="footer-container">
            <div className="footer-heading">HELP</div>
            <div className="footer-content">FAQ</div>
            <div className="footer-content">Delivery Information</div>
            <div className="footer-content">Terms and Condition</div>
            <div className="footer-content">Privacy policy</div>
          </div>
          <div className="footer-container">
            <div className="footer-heading">MY ACCOUNT</div>
            <div className="footer-content">Login</div>
            <div className="footer-content">Signup</div>
          </div>
          <div
            className="footer-container"
            style={{
              flex: 1,
              maxWidth: "10vw",
            }}
          >
            <div className="footer-heading">CONNECT</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: "white",
                justifyContent: "space-between",
                marginTop: "1.5rem",
              }}
            >
              <YouTubeIcon />
              <Instagram />
              <Facebook />
              <WhatsApp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
