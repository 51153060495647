import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./slices/cartSlice";
import userReducer from "./slices/authSlice";
import viewReducer from "./slices/viewSlice";

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
    view: viewReducer,
  },
});
